<template>
<b-overlay rounded="sm" :show="loading">
  <AuthWrapper>
    <template #card>
      <div class="text-center">
        <div>
          <h1>Just a moment</h1>
        </div>
        <div class="my-2">
          <p>Please provide the information below to proceed</p>
        </div>
      </div>
      <b-alert variant="danger" show>
        <div v-if="errorMessage" class="alert-body font-small-2">
          <b-card-text>
            <feather-icon icon="AlertCircleIcon" />
            <small>{{ errorMessage }}</small>
          </b-card-text>
        </div>
      </b-alert>
      <div>
        <validation-observer ref="residentialForm">
          <b-form @submit.prevent="saveOnboardingInformation">
            <b-form-group label="First Name" label-for="first_name">
              <template #label>
                <span>First Name<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="first_name" rules="required">
                <input id="first_name" v-model="first_name" placeholder="Enter your First Name Here" class="form-control">
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Last Name" label-for="last_name">
              <template #label>
                <span>Last Name<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="last_name" rules="required">
                <input id="last_name" v-model="last_name" placeholder="Enter your Last Name Here" class="form-control">
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Gender" label-for="gender" class="mt-2">
              <template #label>
                <span>Gender<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="gender" rules="required">
                <v-select
                  id="gender"
                  v-model="gender"
                  :loading="loading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genderOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Institution" label-for="institution">
              <template #label>
                <span>Institution<span style="color: tomato">*</span></span>
              </template>

             <validation-provider #default="{ errors }" name="institution" rules="required">
                <input id="institution" v-model="institution" placeholder="Enter Institution name here" class="form-control">
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Applicant Status" label-for="applicant_status">
              <template #label>
                <span>Applicant Status<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="applicant_status" rules="required">
                <v-select
                  id="applicant_status"
                  v-model="applicant_status"
                  :loading="loading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[
                    {
                      label: 'Student',
                      value: 'Student'
                    },
                    {
                      label: 'Alumni',
                      value: 'Alumni'
                    }
                  ]"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Sector" label-for="sector">
              <template #label>
                <span>Sector<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="sector" rules="required">
                <v-select
                  id="sector"
                  v-model="sector"
                  :loading="loading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sectorOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Country" label-for="country">
              <template #label>
                <span>Country<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="country" rules="required">
                <v-select
                  id="country"
                  v-model="country"
                  :loading="loading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countryOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div v-if="country === 'Ghana'">
              <b-form-group label="Region" label-for="region">
                <template #label>
                  <span>Region<span style="color: tomato">*</span></span>
                </template>
  
                <validation-provider #default="{ errors }" name="region" rules="required">
                  <v-select
                    id="region"
                    v-model="region"
                    :loading="loading"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="regionOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <b-form-group label="Area" label-for="area">
                <template #label>
                  <span>Area<span style="color: tomato">*</span></span>
                </template>
  
                <validation-provider #default="{ errors }" name="area" rules="required">
                  <v-select
                    id="area"
                    v-model="area"
                    :loading="loading"             
                    :clearable="false"
                    :options="areaOptions"
                    :reduce="val => val.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <b-form-group label="District" label-for="region">
                <template #label>
                  <span>District<span style="color: tomato">*</span></span>
                </template>
  
                <validation-provider #default="{ errors }" name="district" rules="required">
                  <v-select
                    id="district"
                    v-model="district"
                    :loading="loading"             
                    :clearable="false"
                    :options="districtOptions"
                    :reduce="val => val.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <b-form-group label="Assembly" label-for="assembly">
                <template #label>
                  <span>Assembly<span style="color: tomato">*</span></span>
                </template>
  
                <validation-provider #default="{ errors }" name="assembly" rules="required">
                  <v-select
                    id="assembly"
                    v-model="assembly"
                    :loading="loading"             
                    :clearable="false"
                    :options="assemblyOptions"
                    :reduce="val => val.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div v-else>
              <b-form-group label="Region" label-for="region">
                <template #label>
                  <span>Region<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="region" rules="required">
                  <input id="region" v-model="region" placeholder="Enter region name here" class="form-control">
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Area" label-for="area">
                <template #label>
                  <span>Area<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="area" rules="required">
                  <input id="area" v-model="area" placeholder="Enter area name here" class="form-control">
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="District" label-for="district">
                <template #label>
                  <span>District<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="district" rules="required">
                  <input id="district" v-model="district" placeholder="Enter district name here" class="form-control">
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Assembly" label-for="assembly">
                <template #label>
                  <span>Assembly<span style="color: tomato">*</span></span>
                </template>

                <validation-provider #default="{ errors }" name="assembly" rules="required">
                  <input id="assembly" v-model="assembly" placeholder="Enter assembly name here" class="form-control">
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="d-flex my-1 mt-2 justify-content-between align-items-center">
              <div>
                <b-button type="submit" variant="primary" block class="py-1">
                  Proceed
                  <feather-icon icon="ChevronRightIcon" />
                </b-button>
              </div>

              <b-card-text class="text-right mt-0 text-danger cursor-pointer" @click="logout">
                <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                <span>Logout</span>
              </b-card-text>
            </div>

          </b-form>
        </validation-observer>
      </div>
    </template>
  </AuthWrapper>
</b-overlay>
</template>

<script>
import store from '@/store/index'
import { email, required } from '@validations';

import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import {
  BCol,
  BImg,
  BRow,
  BForm,
  BLink,
  BAlert,
  BButton,
  BOverlay,
  BCardText,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormDatepicker
} from 'bootstrap-vue'

import { get } from "lodash";
import vSelect from 'vue-select'

/* eslint-disable global-require */
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'

export default {
  components: {
    AuthWrapper,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BFormSelect,
    BFormDatepicker,
    vSelect,

    
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      hasErrorMessage: false,
      errorMessage: "",

      first_name: '',
      last_name: '',
      gender: '',
      institution: '',
      sector: '',
      applicant_status: '',

      genderOptions: [
        {
          value: "female",
          label: "Female"
        },
        {
          value: "male",
          label: "Male"
        }
      ],
      sectorOptions: [
        "Not applicable",
        "Madina Sector",
        "Kaneshie Sector",
        "Upper Volta Sector",
        "Lower Volta Sector", 
        "Mankessim Sector",
        "Sunyani Sector",
        "Cape Coast Sector",
        "Techiman Sector",
        "Takoradi Sector",
        "Tarkwa Sector",
        "Koforidua Sector",
        "Suhum Sector",
        "Bompata Sector",
        "Kwadaso Sector",
        "Mampong Sector", 
        "Northern Sector",
        "Upper East Sector", 
        "Upper West sector",
        "KNUST CAMPUS",
        "UCC CAMPUS",
        "UEW CAMPUS",
        "UG CAMPUS",
        "PU CAMPUS",
      ].map(title => ({
        label: title,
        value: title
      })),

      country: '',
      region: '',
      district: '',
      area: '',
      assembly: '',

      regionOptions: [],
      areaOptions: [],
      districtOptions: [],
      assemblyOptions: [],
      countryOptions: [],

      // validation
      required,
      email
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    country: {
      handler(changed){
        if (changed){
          this.region = ""
          this.area = ""
          this.district = ""
          this.assembly = ""
        }
      },
      immediate: true
    },
    region: {
      handler(changed){
        if (changed){
          if (this.country !== "Ghana") { return }
          this.fetchChurchAreas();
        }
      },
      immediate: true
    },
    area: {
      handler(changed){
        if (changed){
          if (this.country !== "Ghana") { return }
          this.fetchChurchDistrict();
        }
      },
      immediate: true
    },
    district: {
      handler(changed){
        if (changed){
          if (this.country !== "Ghana") { return }
          this.fetchChurchAssemblies();
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchChurchRegions()
    this.fetchCountries()
  },
  mounted() {
    this.first_name = this.getValueFromSource(this.currentUser, 'first_name');
    this.last_name = this.getValueFromSource(this.currentUser, 'last_name');
    this.sector = this.getValueFromSource(this.currentUser, 'sector');
    this.institution = this.getValueFromSource(this.currentUser, 'institution');
    this.applicant_status = this.getValueFromSource(this.currentUser, 'applicant_status');

    this.gender = this.getValueFromSource(this.currentUser, 'gender');
    this.region = this.getValueFromSource(this.currentUser, 'region');
    this.district = this.getValueFromSource(this.currentUser, 'district');
    this.area = this.getValueFromSource(this.currentUser, 'area');
    this.assembly = this.getValueFromSource(this.currentUser, 'assembly');
  },
  methods: {
    async fetchChurchRegions() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchRegions();
        const { data } = request.data;
        this.regionOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.region = '';
        this.area = '';
        this.district = '';
        this.assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAreas() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAreas(this.region);
        const { data } = request.data;
        this.areaOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.area = '';
        this.district = '';
        this.assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchDistrict() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchDistricts(this.region, this.area);
        const { data } = request.data;
        this.districtOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.district = '';
        this.assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChurchAssemblies() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchChurchAssemblies(this.region, this.area, this.district);
        const { data } = request.data;
        this.assemblyOptions = data.map(opt => ({
          label: opt.name,
          value: opt.name
        }));

        this.assembly = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchCountries() {
      try {
        const response = await this.useJwt().sharedService.fetchCountries()

        const countries = get(response, 'data.data')
        this.countryOptions = countries.map(country => ({
          label: country.country,
          value: country.country
        }));
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    async saveOnboardingInformation() {
      try {
        this.loading = true;

        const success = await this.$refs.residentialForm.validate();
        if (!success) {
          return;
        }

        const {
          gender,
          first_name,
          last_name,
          region,
          sector,
          institution,
          applicant_status,
          area,
          district,
          assembly,
          country
        } = this;

        const formData = new FormData();
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("gender", gender);
        formData.append("sector", sector);
        formData.append("institution", institution);
        formData.append("applicant_status", applicant_status);

        formData.append("region", region);
        formData.append("district", district);
        formData.append("area", area);
        formData.append("assembly", assembly);
        formData.append("country", country);
        
        await this.useJwt().authService.saveOnboardingData(formData);

        this.$router.replace({
          name: "client-home"
        })
        .catch(() => {});
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.errorMessage = error_message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
