var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"rounded":"sm","show":_vm.loading}},[_c('AuthWrapper',{scopedSlots:_vm._u([{key:"card",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('div',[_c('h1',[_vm._v("Just a moment")])]),_c('div',{staticClass:"my-2"},[_c('p',[_vm._v("Please provide the information below to proceed")])])]),_c('b-alert',{attrs:{"variant":"danger","show":""}},[(_vm.errorMessage)?_c('div',{staticClass:"alert-body font-small-2"},[_c('b-card-text',[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',[_vm._v(_vm._s(_vm.errorMessage))])],1)],1):_vm._e()]),_c('div',[_c('validation-observer',{ref:"residentialForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveOnboardingInformation($event)}}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("First Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name),expression:"first_name"}],staticClass:"form-control",attrs:{"id":"first_name","placeholder":"Enter your First Name Here"},domProps:{"value":(_vm.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.first_name=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Last Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name),expression:"last_name"}],staticClass:"form-control",attrs:{"id":"last_name","placeholder":"Enter your Last Name Here"},domProps:{"value":(_vm.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.last_name=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Gender","label-for":"gender"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Gender"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"gender","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.genderOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Institution","label-for":"institution"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Institution"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"institution","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.institution),expression:"institution"}],staticClass:"form-control",attrs:{"id":"institution","placeholder":"Enter Institution name here"},domProps:{"value":(_vm.institution)},on:{"input":function($event){if($event.target.composing){ return; }_vm.institution=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Applicant Status","label-for":"applicant_status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Applicant Status"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"applicant_status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"applicant_status","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":[
                    {
                      label: 'Student',
                      value: 'Student'
                    },
                    {
                      label: 'Alumni',
                      value: 'Alumni'
                    }
                  ],"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.applicant_status),callback:function ($$v) {_vm.applicant_status=$$v},expression:"applicant_status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Sector","label-for":"sector"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Sector"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"sector","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"sector","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.sectorOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.sector),callback:function ($$v) {_vm.sector=$$v},expression:"sector"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Country","label-for":"country"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Country"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"country","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.country === 'Ghana')?_c('div',[_c('b-form-group',{attrs:{"label":"Region","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Region"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,false,3915155647)},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"region","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.regionOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3372230568)})],1),_c('b-form-group',{attrs:{"label":"Area","label-for":"area"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Area"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,false,2943210320)},[_c('validation-provider',{attrs:{"name":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"area","loading":_vm.loading,"clearable":false,"options":_vm.areaOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3763436519)})],1),_c('b-form-group',{attrs:{"label":"District","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("District"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,false,3846550881)},[_c('validation-provider',{attrs:{"name":"district","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"district","loading":_vm.loading,"clearable":false,"options":_vm.districtOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1056775414)})],1),_c('b-form-group',{attrs:{"label":"Assembly","label-for":"assembly"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Assembly"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,false,2843020057)},[_c('validation-provider',{attrs:{"name":"assembly","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"assembly","loading":_vm.loading,"clearable":false,"options":_vm.assemblyOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.assembly),callback:function ($$v) {_vm.assembly=$$v},expression:"assembly"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2502646222)})],1)],1):_c('div',[_c('b-form-group',{attrs:{"label":"Region","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Region"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.region),expression:"region"}],staticClass:"form-control",attrs:{"id":"region","placeholder":"Enter region name here"},domProps:{"value":(_vm.region)},on:{"input":function($event){if($event.target.composing){ return; }_vm.region=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Area","label-for":"area"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Area"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.area),expression:"area"}],staticClass:"form-control",attrs:{"id":"area","placeholder":"Enter area name here"},domProps:{"value":(_vm.area)},on:{"input":function($event){if($event.target.composing){ return; }_vm.area=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"District","label-for":"district"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("District"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"district","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.district),expression:"district"}],staticClass:"form-control",attrs:{"id":"district","placeholder":"Enter district name here"},domProps:{"value":(_vm.district)},on:{"input":function($event){if($event.target.composing){ return; }_vm.district=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Assembly","label-for":"assembly"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Assembly"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"assembly","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.assembly),expression:"assembly"}],staticClass:"form-control",attrs:{"id":"assembly","placeholder":"Enter assembly name here"},domProps:{"value":(_vm.assembly)},on:{"input":function($event){if($event.target.composing){ return; }_vm.assembly=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"d-flex my-1 mt-2 justify-content-between align-items-center"},[_c('div',[_c('b-button',{staticClass:"py-1",attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Proceed "),_c('feather-icon',{attrs:{"icon":"ChevronRightIcon"}})],1)],1),_c('b-card-text',{staticClass:"text-right mt-0 text-danger cursor-pointer",on:{"click":_vm.logout}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"LogOutIcon"}}),_c('span',[_vm._v("Logout")])],1)],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }